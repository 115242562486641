import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import SliceZone from "../components/SliceZone"


export const query = graphql`
  query myPost($uid: String, $lang: String) {
    desktop: file(relativePath: {eq: "famdesktop.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp

        }
      }
    }
    mobile: file(relativePath: {eq: "fammobile.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 490) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    couple: file(relativePath: {eq: "couple.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 610) {
					...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prismic {
      allPages(uid: $uid, lang: $lang) {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
              alternateLanguages {
                uid
                id
                type
                lang
              }
            }
            page_content {
              ... on PRISMIC_PagePage_contentCall_to_action {
                type
                primary {
                  action_text
                  action_title
                  black_button
                }
              }
              ... on PRISMIC_PagePage_contentCards_carousel {
                type
                fields {
                  image
                  content
                }
                primary {
                  title
                }
              }
              ... on PRISMIC_PagePage_contentCertificates {
                type
                primary {
                  first_cert
                  second_cert
                }
              }
              ... on PRISMIC_PagePage_contentFull_width_image {
                type
                primary {
                  background_image
                }
              }
            }
          }
        }
      }
      allMenus(lang: $lang) {
        edges {
          node {
            menu_links {
              label
              local
              footer
              link {
                ... on PRISMIC_Homepage {
                  _meta {
                    uid
                    id
                    lang
                    type
                    alternateLanguages {
                      uid
                      id
                      lang
                      type
                    }
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                    id
                    lang
                    type
                    alternateLanguages {
                      uid
                      id
                      lang
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {

  const prismicContentHome = data.prismic.allPages.edges[0]
  if (!prismicContentHome) return null
  const homepage = prismicContentHome.node

  const prismicContentMenu = data.prismic.allMenus.edges[0]
  if (!prismicContentMenu) return null
  const menu = prismicContentMenu.node

  const desktop = data.desktop.childImageSharp.fluid
  const mobile = data.mobile.childImageSharp.fluid
  const couple = data.couple.childImageSharp.fluid
  
  return (
    <Layout menuLinks={menu} activeDoc={homepage._meta}>
      <SliceZone mobile={mobile} desktop={desktop} slices={homepage.page_content} couple={couple}/>
    </Layout>
  )}

export default Page
